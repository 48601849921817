

.block_cloud{
  font-size: 30px;
  margin: 0;
  cursor: pointer;
  color: green;
}
.upload_label{
  margin: 0;
}
.cir-text {
  text-align: justify;
  text-justify: inter-word;
}

.cir-text::first-letter {
  font-size: 150%;
  /* color: #8A2BE2; */
}

.my-control:after {
  content: "*";
  color: red;
}
.relative {
  position: relative;
}
.fixed {
  position: sticky;
  bottom: 1rem;
  z-index: 999;
}
.bg-image {
  background-image: url("./assets/image/img\ school.jpg");
  background-size: cover;
  height: 100vh;
}
.bg-logo {
  background-image: url("./assets/images/users/SPS\ Logo\ -\ Golden.png");
  background-size: cover;
  height: 100vh;
}
.my-cursor {
  cursor: pointer;
  background-color: #556ee6;
  color: #fff;
  /* border: 1px solid #fff; */
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

.outgoing-chats {
  overflow: hidden;
  margin: 10px 20px;
}

.outgoing-chats-msg p {
  background-color: #3a12ff;
  background-image: linear-gradient(
    45deg,
    #ee087f 0%,
    #dd2a7b 25%,
    #9858ac 50%,
    #8134af 75%,
    #515bd4 100%
  );
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
  padding: 1rem;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 5px 2px;
}
.outgoing-chats-msg {
  float: right;
  width: 46%;
}

/* Styling the avatar */
.outgoing-chats-img {
  display: inline-block;
  width: 50px;
  float: right;
  margin-right: 1rem;
}

/* Styling the avatar  */
.received-chats-img {
  display: inline-block;
  width: 50px;
  float: left;
}

.received-msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}
.received-msg-inbox {
  width: 57%;
}

.received-msg-inbox p {
  background: #efefef none repeat scroll 0 0;
  border-radius: 10px;
  color: #646464;
  font-size: 14px;
  margin-left: 1rem;
  padding: 1rem;
  width: 100%;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 5px 2px;
}
p {
  overflow-wrap: break-word;
}

.cursor {
  cursor: pointer !important;
}

.loading {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: grid;
  place-items: center;
  max-width: 100%;
}

.loading > div {
  width: 10vmax;
  height: 10vmax;
  border-bottom: 5px solid #556ee6;
  border-radius: 50%;
  animation: loadingRotate 700ms linear infinite;
}

@keyframes loadingRotate {
  to {
    transform: rotateZ(-360deg);
  }
}

.select2-selection__menu {
  background-color: white !important;
}