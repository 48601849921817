// 
// Contacts.scss
//

.contact-links{
  a{
    color: var(--#{$prefix}body-color);
  }
}

// profile
.hidden_upload{
  display: none;

}
.profile-user-wid{
  margin-top: -46px;
}
.my_post{
  width: 100%;
  height: 18.5rem;
}
.img_height{
  height: 90vh;
 
}
.crop_img{
  width: 10rem;
  padding: .6rem;
}

